<template>
  <pimples-template
    :gender="gender"
    :prone-to-acne="proneToAcne"
    :prone-to-acne-ai-selected="proneToAcneAiSelected"
    :facial-acne-coverage-ai-selected="facialAcneCoverageAiSelected"
    :facial-acne-coverage="facialAcneCoverage"
    :acne-discomfort-frequency="acneDiscomfortFrequency"
    :first-acne-occurrence="firstAcneOccurrence"
    :acne-detail-questions-visible="isAcneDetailQuestionsVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import PimplesTemplate from '@/modules/questionnaire/components/steps/photo-analysis/pimples/pimples/PimplesTemplate';

import { makeStep, mapAiSelectedFields, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { yes, no } from '@/modules/questionnaire/api/helpers';

const { field, requiredField } = fieldBuilder;

const FIELDS_TO_RESET = ['acneDiscomfortFrequency', 'firstAcneOccurrence'];

const CONDITIONAL_FIELDS = ['acneDiscomfortFrequency', 'firstAcneOccurrence', 'facialAcneCoverage'];

const AI_SELECTED_FIELD_NAMES = ['proneToAcne', 'facialAcneCoverage'];

const FIELDS = [
  field('gender'),
  ...AI_SELECTED_FIELD_NAMES.map(requiredField),
  ...FIELDS_TO_RESET.map(requiredField)
];

export default {
  name: 'Pimples',
  components: {
    PimplesTemplate
  },
  mixins: [makeStep(FIELDS), mapAiSelectedFields(AI_SELECTED_FIELD_NAMES), resetStoreValues],
  computed: {
    isAcneDetailQuestionsVisible() {
      return yes(this.proneToAcne);
    }
  },
  watch: {
    proneToAcne(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(CONDITIONAL_FIELDS);
        this.showNextStep();

        return;
      }

      this.scrollTo('#facial-acne-coverage');
    },
    facialAcneCoverage() {
      this.scrollTo('#acne-discomfort-frequency');
    },
    acneDiscomfortFrequency() {
      this.scrollTo('#first-acne-occurrence');
    },
    firstAcneOccurrence() {
      this.showNextStep();
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (CONDITIONAL_FIELDS.includes(fieldName)) {
        return this.isAcneDetailQuestionsVisible;
      }

      return true;
    }
  }
};
</script>
